export const unixToDateString = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };
  return new Intl.DateTimeFormat('sv-SE', dateOptions).format(date);
};

export const generateWeeklySegments = (startTimestamp, endTimestamp) => {
  const weeks = [];
  // startTimestamp = Date.now() / 1000;
  let currentStart = new Date(startTimestamp * 1000); // Convert start timestamp to Date
  const endDate = new Date(endTimestamp * 1000); // Convert end timestamp to Date
  let weekIndex = 1; // Start the week count from 1.

  while (currentStart <= endDate) {
    // Calculate the end of the current week (6 days after currentStart)
    const currentEnd = new Date(currentStart);
    currentEnd.setDate(currentStart.getDate() + 6);

    // If the remaining days are less than a week, combine them with the previous week
    const segmentEnd = currentEnd > endDate ? endDate : currentEnd;

    // Check if we are at the last week and it is not a full week
    if (segmentEnd === endDate && (segmentEnd - currentStart) / (1000 * 60 * 60 * 24) < 6) {
      // Combine with the previous week
      if (weeks.length > 0) {
        const lastWeek = weeks.pop(); // Remove the last week
        currentStart = new Date(lastWeek.start); // Adjust the start to the previous week's start
        weekIndex--; // Adjust the week index
      }
    }

    // Format dates as strings (MM/DD)
    const startFormatted = `${currentStart.getDate().toString().padStart(2, '0')}/${(currentStart.getMonth() + 1).toString().padStart(2, '0')}`;
    const endFormatted = `${segmentEnd.getDate().toString().padStart(2, '0')}/${(segmentEnd.getMonth() + 1).toString().padStart(2, '0')}`;

    // Push the segment into the weeks array
    weeks.push({
      label: `Tuần ${weekIndex}: (${startFormatted} - ${endFormatted})`,
      value: `${currentStart.toLocaleDateString('sv-SE')} / ${segmentEnd.toLocaleDateString('sv-SE')}`,
      start: currentStart.toISOString(), // Keep for merging last week
      end: segmentEnd.toISOString()
    });

    // Move to the start of the next week
    currentStart = new Date(segmentEnd);
    currentStart.setDate(currentStart.getDate() + 1);

    weekIndex++;
  }

  return weeks;
};


export const generateRemainWeeklySegments = (startTimestamp, endTimestamp) => {
  const weeks = [];
  let currentStart = new Date(startTimestamp * 1000); // Convert start timestamp to Date
  const endDate = new Date(endTimestamp * 1000); // Convert end timestamp to Date
  let weekIndex = 1; // Start the week count from 1.

  while (currentStart <= endDate) {
    // Calculate the end of the current week (6 days after currentStart)
    const currentEnd = new Date(currentStart);
    currentEnd.setDate(currentStart.getDate() + 6);

    // If the remaining days are less than a week, combine them with the previous week
    const segmentEnd = currentEnd > endDate ? endDate : currentEnd;

    // Check if we are at the last week and it is not a full week
    if (segmentEnd === endDate && (segmentEnd - currentStart) / (1000 * 60 * 60 * 24) < 6) {
      // Combine with the previous week
      if (weeks.length > 0) {
        const lastWeek = weeks.pop(); // Remove the last week
        currentStart = new Date(lastWeek.start); // Adjust the start to the previous week's start
        weekIndex--; // Adjust the week index
      }
    }

    // Format dates as strings (MM/DD)
    const startFormatted = `${currentStart.getDate().toString().padStart(2, '0')}/${(currentStart.getMonth() + 1).toString().padStart(2, '0')}`;
    const endFormatted = `${segmentEnd.getDate().toString().padStart(2, '0')}/${(segmentEnd.getMonth() + 1).toString().padStart(2, '0')}`;

    // Push the segment into the weeks array
    weeks.push({
      label: `Tuần ${weekIndex}: (${startFormatted} - ${endFormatted})`,
      value: `${currentStart.toLocaleDateString('sv-SE')} / ${segmentEnd.toLocaleDateString('sv-SE')}`,
      start: currentStart.toISOString(), // Keep for merging last week
      end: segmentEnd.toISOString()
    });

    // Move to the start of the next week
    currentStart = new Date(segmentEnd);
    currentStart.setDate(currentStart.getDate() + 1);

    weekIndex++;
  }

  return weeks;
};





export const extractStartDate = (dateRange) =>{
  // Split the date range by the separator " / "
  const dates = dateRange.split(" / ");
  
  // Return the start date (first part of the array)
  return dates[0]; // "2025-02-02"
};

export const extractEndDate = (dateRange) =>{
  // Split the date range by the separator " / "
  const dates = dateRange.split(" / ");
  
  // Return the start date (first part of the array)
  return dates[0]; // "2025-02-02"
};


export const convertToTimestamp = (dateString) => {
  // Convert the date string to a Date object
  const dateObj = new Date(dateString);
  
  // Convert the Date object to a timestamp in seconds
  const timestamp = Math.floor(dateObj.getTime() / 1000) - 7 * 60 * 60; // getTime() returns milliseconds, divide by 1000 to get seconds and subtract 7 hours
  
  return timestamp;
};

export const getRemainingWeeks = (startTimestamp, endTimestamp)=> {
  // const currentDate = new Date();
  // const currentTimestamp = Math.floor(currentDate.getTime());
  
  if (startTimestamp > endTimestamp) {
      return "Đã kết thúc";
  } else {
      // Convert milliseconds to weeks
      const remainTime = endTimestamp - startTimestamp;
      const remainingWeeks = Math.ceil(remainTime / (7 * 24 * 60 * 60 * 1000));
      return remainingWeeks;
  }
};



